.outView {
    background-color: #F8F8F8;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.contentView {
    width: 600px;
    box-sizing: border-box;
    padding: 15px 5px;
}

.contentLeft{
    width: 100%;
    display: grid;
    grid-template-rows: fit-content(30px) fit-content(30px) auto;
    text-align: left;
}
.contentRight{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.contentLeftTitle{
    color: #333333;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 10px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    color: #333333;
}
.contentLeftTitle2{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #666666;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 10px;
}

.contentRightTitle{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 10px;
}

.contentFirst{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    text-align: left;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */


    color: #666666;

}

.contentFirstTextAbout{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 10px;
}

.contentFirstTextAboutContent{
    margin-bottom: 10px;
}
