.outView{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content{
    margin-top: 50px;
    margin-bottom: 50px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.text{
    width: 100%;
    word-break: break-all;
    color: #654a00;
    font-weight: 1000;
    font-size: 20px;
    text-align: left;
    margin: 10px 0;
}

.adImg{
    width: 100%;
}

.hightLeight{
    color: coral;
}
